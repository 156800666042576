import React, { useEffect, useState } from "react";
import {
  Padding,
  RColumn,
  RRow,
  RText,
  RTitile,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../firebase";
import { greenColor, redColor } from "../profile/profilePage";
import { ColorPalette } from "../global/const";
import { ContainerBorder } from "../compnents/compes";
import { isMobile } from "react-device-detect";
import { Leaderboard2 } from "../p2eGames/lead2";

let cachePnlLead = null;

export const GlobalPnLPage = React.memo(() => {
  const [leadEntries, setLeadEntries] = useState([]);

  useEffect(() => {
    getLead();
    console.log("d");
  }, []);

  async function getLead() {
    if (cachePnlLead) {
      setLeadEntries(cachePnlLead);
      return;
    }
    const statsRef = collection(db, "stats_leaderboard");
    const q = query(statsRef, orderBy("pnl", "desc"), limit(10));

    const docSnapshot = await getDocs(q);
    let newEntry = [];
    let i = 1;
    docSnapshot.forEach((entry) => {
      newEntry.push({
        ...entry.data(),
        rank: i,
        uid: entry.id,
        id: entry.id,
        score: Math.abs(entry.data().pnl + addDiff()).toFixed(0),
      });
      i++;
    });
    cachePnlLead = newEntry;
    setLeadEntries(newEntry);
  }
  function addDiff() {
    const h = new Date().getUTCHours();
    const x = 24 - h;

    return x * 64.5;
  }
  function SingleEntry({ entry }) {
    return (
      <ContainerBorder
        gradientBg
        heightMobile=""
        heightPc=""
        margin="8px 0px"
        padding="12px 20px"
      >
        <RRow
          width={"100%"}
          horizontalAlign="space-between"
          verticalAlignment="center"
        >
          <RRow verticalAlignment="center">
            <RTitile size={isMobile ? "20px" : undefined}>{entry.rank}</RTitile>
            <SpaceHorizontal />
            <RText weight="bold" size={isMobile ? "16px" : undefined}>
              {entry.username ??
                "GHUB Player" + entry.uid.slice(0, 3).slice(0, 20)}
            </RText>
          </RRow>
          <RColumn horizontalAlign="end">
            <RTitile
              size={isMobile ? "18px" : undefined}
              color={entry.pnl > 0 ? greenColor : redColor}
            >
              {entry.pnl > 0 ? "+" : "-"}$
              {Math.abs(entry.pnl + addDiff()).toFixed(1)}
            </RTitile>
            {/*  <RText align={"left"}>{entry.total_win.count} wins +${entry.total_win.usd.toFixed(1)}</RText>
                            <RText>{entry.total_loss.count} losses -${entry.total_loss.usd.toFixed(1)}</RText> */}
          </RColumn>
        </RRow>
      </ContainerBorder>
    );
  }
  return (
    <>
      {/*
      <Space />
       <RTitile align={"center"}>Global PnL</RTitile>
      <RText align={"center"}>Top 5</RText>
      <Space />
      <RColumn width={"100%"}>
        {leadEntries.map((entry, ind) => (
          <SingleEntry entry={entry} key={ind} />
        ))}
      </RColumn> */}

      <Leaderboard2
        gameName={"Global PnL"}
        players={leadEntries}
        scoreSuffix="+$"
        scoreColor={greenColor}
      />
    </>
  );
});
