import { useEffect, useState } from "react";
import { auth } from "../firebase";

export default function CasinoApp() {
  const [userToken, setUserToken] = useState();

  useEffect(() => {
    auth.currentUser.getIdToken().then((t) => setUserToken(t));
  }, []);
  return (
    <>
      <iframe
        width={"100%"}
        height={window.innerHeight - 3}
        style={{ border: "none" }}
        src={`https://tg-casino-inky.vercel.app/#/?tokenAuth=${userToken}`}
      />
    </>
  );
}
