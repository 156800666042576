import { useLocation, useNavigate } from "react-router-dom";
import {
  BackToHomeBtn,
  LoadingScreen,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { telegramID, tgUsernameGlob } from "../aStartingPage";

import { analytics, auth, db, functions } from "../firebase";
import { useEffect, useState } from "react";
import {
  BaseInfo,
  ColorPalette,
  getUserBalances,
  isTest,
} from "../global/const";
import {
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import leadCellImg from "../assets/leaderboard/panel.png";
import bgLeadPanelImg from "../assets/leaderboard/bg.png";
import { logEvent } from "firebase/analytics";
import { httpsCallable } from "firebase/functions";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../compnents/errorMessagesComp";
import { ToastContainer } from "react-toastify";
import { levelImgs } from "../home/tabController";
import { Leaderboard2 } from "./lead2";
import { formatNumber, getLevelInd, isP2eCompActive } from "../global/util";
import { ContainerBorder, CountdownTimer } from "../compnents/compes";
import { useGameType } from "../theme";
import { showAds } from "../adsGram/adsManager";

let ticketCode = null;

export default function P2eGame() {
  let [h, setH] = useState(window.innerHeight);
  const [isViewingLeaderboard, setIsViewingLeaderbord] = useState(true);
  const nav = useNavigate();
  const location = useLocation();
  const link = location.state.link;
  const url = location.state.url;
  const [isMobile, setIsMobile] = useState(true);

  const [leadData, setLeadData] = useState([null]);

  const [userLeadData, setUserLeadData] = useState(); //solo se non in top

  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState("init");

  const { userBalances } = useGameType();
  const [coinSelected, setCoinSelected] = useState(null);

  const [p2eAttemps, setP2eAttemps] = useState({
    lastTryDate: 0,
    currentTry: 0,
    ads_watched: 0,
    last_ads_attemps_given_date: 0,
  });
  const isgameActive = isTest
    ? true
    : BaseInfo.p2eGames.games_active.some((g) => g.url === url) &&
      isP2eCompActive();

  const param = `user_id=${auth.currentUser.uid}&game_id=${url}`;

  function back() {
    nav("/?username=" + tgUsernameGlob + "&id=" + telegramID);
  }

  useEffect(() => {
    getLead();
    const userDoc = doc(db, "users", auth.currentUser.uid);
    const unsub = onSnapshot(userDoc, (snapshot) => {
      if (snapshot.exists()) updateP2eData(snapshot.data());
    });
    return () => unsub();
  }, []);

  async function postFinished(score) {
    console.log("fin", ticketCode);

    const funct = httpsCallable(functions, "postP2eScore");
    const data = {
      gameUrl: url,
      score: score,
      ticketCode: ticketCode,
    };
    try {
      const res = await funct(data);
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function postStart() {
    const newDat = {
      gameUrl: url,
      date: Date.now(),
    };
    const colRef = collection(db, `users/${auth.currentUser.uid}/startP2e`);
    const docRef = await addDoc(colRef, newDat);
    ticketCode = docRef.id;
  }
  useEffect(() => {
    const riceviMessaggio = (event) => {
      if (event.data.type === "start_" + url) {
        console.log("STTTTT");
        postStart();
      }
      if (event.data.type === "end_" + url) {
        console.log("ENDDDD", event.data.score);
        const score = event.data.score;
        postFinished(score);
      }
    };
    window.addEventListener("message", riceviMessaggio);

    return () => {
      window.removeEventListener("message", riceviMessaggio);
    };
  }, []);
  useEffect(() => {
    setIsMobile(window.innerWidth < 600);

    window.addEventListener("resize", resizeGameWindow);

    document.addEventListener("DOMContentLoaded", resizeGameWindow);

    return () => {
      console.log("dd");
      window.removeEventListener("resize", resizeGameWindow);
      window.removeEventListener("DOMContentLoaded", resizeGameWindow);
    };
  }, []);

  useEffect(() => {
    resizeGameWindow();
  }, [isViewingLeaderboard, isMobile]);

  function resizeGameWindow() {
    setIsMobile(window.innerWidth < 600);
    setH(window.innerHeight);

    if (window.innerWidth < 600) return;

    var iframe_id = "gameFrame";

    var gameWindowBox = document.getElementById(iframe_id);
    if (!gameWindowBox) return;

    if (url === "pinball") {
      gameWindowBox.style.width = window.innerWidth + "px";

      gameWindowBox.style.height = window.innerHeight + "px";
      return;
    }

    var offset_multiplier = 0.99;
    var min_width = 320; // Minimum width for the iframe
    var original_game_width = 800;

    var original_game_height = 600;
    var max_height_percentage = 0.99;

    var aspect_ratio = original_game_width / original_game_height;
    var max_width = window.innerWidth * offset_multiplier;
    var max_height = window.innerHeight * max_height_percentage;

    var game_width = Math.max(
      min_width,
      Math.min(max_width, window.innerWidth)
    );

    var game_height = game_width / aspect_ratio;

    if (game_height > max_height) {
      game_height = max_height;

      game_width = game_height * aspect_ratio;
    }
    gameWindowBox.style.width = game_width + "px";

    gameWindowBox.style.height = game_height + "px";
  }
  function isLastAdsAttempsYesterday() {
    const todayUtcMidnight = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate()
      )
    ).getTime();
    return (p2eAttemps?.last_ads_attemps_given_date ?? 0) < todayUtcMidnight;
  }
  function remainingAttemps(data = p2eAttemps) {
    const todayUtcMidnight = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate()
      )
    ).getTime();
    if ((data?.lastTryDate ?? 0) >= todayUtcMidnight) {
      return (
        BaseInfo.baseInfoDoc.levels[getLevelInd()].attemptP2E -
        (data?.currentTry ?? 0)
      );
    }
    return BaseInfo.baseInfoDoc.levels[getLevelInd()].attemptP2E;
  }

  function hasReachMaxAttemps(data = p2eAttemps) {
    const remaiing = remainingAttemps(data);
    return remainingAttemps(data) <= 0;

    const todayUtcMidnight = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate()
      )
    ).getTime();

    return (
      data.lastTryDate >= todayUtcMidnight &&
      (data?.currentTry ?? 0) >=
        BaseInfo.baseInfoDoc.levels[getLevelInd()].attemptP2E
    );
  }
  function updateP2eData(data) {
    setP2eAttemps(data.p2eAttemps);

    if (hasReachMaxAttemps(data.p2eAttemps)) {
      getLead();
      setIsViewingLeaderbord(true);
    } else {
      setState("init");
    }
  }
  async function getLead() {
    try {
      const leadRef = doc(db, `leaderboards/${url}`);

      const querySnapshot = await getDoc(leadRef);
      setLeadData([]);
      const leaderboardData = Object.entries(querySnapshot.data()?.top50 ?? {})
        .sort(([, a], [, b]) => b.score - a.score)
        .slice(0, 15)
        .map(([id, player], index) => ({ id, ...player, rank: index + 1 }));

      setLeadData(leaderboardData);

      if (!leaderboardData.find((p) => p.id === auth.currentUser.uid)) {
        const usrDoc = await getDoc(
          doc(db, `leaderboards/${url}/scores`, auth.currentUser.uid)
        );
        if (
          !usrDoc.exists() ||
          usrDoc.data().score ===
            0 /* || !isLeadDateValid(usrDoc.data().date) */
        ) {
          //ultimo
          setUserLeadData({
            username: auth.currentUser.displayName ?? tgUsernameGlob,
            score: 0,
            rank: "-",
            avatarUrl: auth.currentUser.photoURL,
          });
          return;
        }
        const q = query(
          collection(db, `leaderboards/${url}/scores`),
          where("score", ">", usrDoc.data().score)
        );
        const pos = await getCountFromServer(q);
        setUserLeadData({
          username: usrDoc.data()["username"] ?? tgUsernameGlob,
          score: usrDoc.data()["score"],
          rank: pos.data().count + 1,
          avatarUrl: auth.currentUser.photoURL,
        });
      }
    } catch (e) {
      //console.log(e);
      setLeadData([]);
    }
  }

  async function wantToPlay(price) {
    if (!hasReachMaxAttemps()) {
      goToGame();
      return;
    }
    console.log(
      price,
      userBalances?.balances?.[coinSelected.toLowerCase()]?.usd_amount
    );
    if (
      price >
      (userBalances?.balances?.[coinSelected.toLowerCase()]?.usd_amount ?? 0)
    ) {
      showErrorNotification("Insufficent balance");
      return;
    }
    setIsLoading(true);
    const funct = httpsCallable(functions, "payP2eGame");
    try {
      const res = await funct({ tokenSymbol: coinSelected });
      console.log(res.data);
      if (res.data.success !== true) throw res.data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setState("init");
    goToGame();
  }

  function goToGame() {
    logEvent(analytics, "play_p2e", { gameUrl: url });
    setIsViewingLeaderbord(false);
  }
  function BalancesSelect() {
    return (
      <>
        {BaseInfo.baseInfoDoc.coinSupported
          .filter((v) => v.symbol !== "GHUB")
          .map((v, i) => (
            <ContainerBorder
              width="92%"
              gradientBg={v.symbol === coinSelected}
              key={v.symbol}
              padding="10px"
              onClick={() => {
                setCoinSelected(v.symbol);
              }}
            >
              <RRow horizontalAlign="space-between">
                <RRow>
                  <img src={v.img} width={"18px"} />
                  <SpaceHorizontal />
                  <RText size="18px">{v.symbol}</RText>
                </RRow>
                <RColumn horizontalAlign="end">
                  <RText size="15px">
                    $
                    {formatNumber(
                      userBalances?.balances?.[v.symbol.toLowerCase()]
                        ?.usd_amount ?? 0,
                      2
                    )}
                  </RText>
                  <RText color="grey" size="14px">
                    {formatNumber(
                      userBalances?.balances?.[v.symbol.toLowerCase()]
                        ?.amount ?? 0,
                      5
                    )}
                  </RText>
                </RColumn>
              </RRow>
            </ContainerBorder>
          ))}
      </>
    );
  }
  function PlayType() {
    console.log(isLastAdsAttempsYesterday());
    if (hasReachMaxAttemps()) {
      return (
        <RRow width={"100%"} horizontalAlign="center">
          <PrimaryBtn
            text={
              "BUY 3 attemps $" +
              BaseInfo.baseInfoDoc.levels[getLevelInd()].priceP2E
            }
            onClick={() => setState("choose_currency")}
          />
          {isLastAdsAttempsYesterday() && (
            <>
              {" "}
              <SpaceHorizontal />
              <PrimaryBtn
                text={`Watch ${
                  5 - (p2eAttemps.ads_watched ?? 0)
                } ads for 3 attemps`}
                onClick={() => showAds(isTest ? "3195" : "3194")}
              />
            </>
          )}
        </RRow>
      );
    }
    return <PrimaryBtn onClick={wantToPlay} text={"PLAY"}></PrimaryBtn>;
  }
  if (state === "choose_currency") {
    const price = BaseInfo.baseInfoDoc.levels[getLevelInd()].priceP2E;
    return (
      <>
        <ToastContainer />
        {isLoading && <LoadingScreen />}
        {
          <div
            style={{ cursor: "pointer", position: "fixed", top: 12, left: 12 }}
            onClick={() => setState("init")}
          >
            <RText>Close</RText>
          </div>
        }
        <RColumn verticalAlignment="space-between" height={"90vh"}>
          <RColumn width={"100%"}>
            <Space height="40px" />
            <RTitile>Select token to pay</RTitile>
            <RTitile>${price}</RTitile>
            <Space />
            <BalancesSelect />
          </RColumn>
          <PrimaryBtn
            text={"Buy 3 attemps"}
            onClick={() => wantToPlay(price)}
          />
        </RColumn>
      </>
    );
  }
  if (isViewingLeaderboard) {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          minHeight: "95vh",
          height: "100%",
          background: `radial-gradient(circle at center, rgba(255, 255, 255, 0.3), #13122D 60%)`,
          justifyContent: "center",
          alignItems: "start",
          paddingTop: "20px",
        }}
      >
        {isLoading && <LoadingScreen />}
        <ToastContainer />
        <BackToHomeBtn />
        <Space height="36px" />
        <RRow horizontalAlign="center">
          <RText>{remainingAttemps()}</RText>
          <SpaceHorizontal />
          <RText color="grey">Free attemps left</RText>
        </RRow>
        {hasReachMaxAttemps() && (
          <RText color={ColorPalette.accentColor} align={"center"}>
            Unlocks midnight UTC
          </RText>
        )}
        <Space />
        {isgameActive ? (
          <CountdownTimer />
        ) : (
          <ContainerBorder>
            <RText align={"center"}>Leaderboard closed</RText>
          </ContainerBorder>
        )}

        <Leaderboard2
          players={leadData}
          gameName={BaseInfo.gamesName[url]}
          userP={userLeadData}
        />
        <Space height="120px" />
        <div
          style={{
            position: "fixed",
            bottom: "24px",
            width: "100%",
          }}
        >
          <RColumn>{isgameActive && <PlayType />}</RColumn>
        </div>
      </div>
    );
  }
  return (
    <>
      <div style={{ position: "fixed", top: "30px", left: "10px" }}>
        <SecondaryBtn onClick={back} text={"Exit"}></SecondaryBtn>
      </div>
      <RColumn verticalAlignment="center">
        <iframe
          id="gameFrame"
          height={isMobile ? h : undefined}
          width={isMobile ? "100%" : undefined}
          src={link + "?" + param}
          style={{ border: "none" }}
        />
      </RColumn>
    </>
  );
}
