import { useLocation, useNavigate } from "react-router-dom";
import {
  BackToHomeBtn,
  EmojiPanel,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  ScorePanel,
  SecondaryBtn,
  Space,
} from "../compnents/otherCOmps";
import { telegramID, tgUsernameGlob } from "../aStartingPage";

import React, { useEffect, useRef, useState } from "react";
import {
  off,
  onChildChanged,
  onDisconnect,
  onValue,
  ref,
  set,
} from "firebase/database";
import { auth, db, functions, rdb } from "../firebase";
import { ColorPalette, isTest } from "../global/const";
import { httpsCallable } from "firebase/functions";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../compnents/errorMessagesComp";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";

export default function ScoreGameRealTime() {
  let [h, setH] = useState(window.innerHeight);
  const nav = useNavigate();
  const location = useLocation();
  const { gameID, price, url, players_count, level, players, isCasa } =
    location.state;

  let isSpectator = location.state.isSpectator;
  if (!isSpectator) isSpectator = 0;

  const urlToIndexHtml = {
    footpong: `foot_pong2/index.html?roomID=${gameID}&playerID=${auth.currentUser.uid}&playerName=${auth.currentUser.displayName}`,
    airhockey: "AirHockeyV11/index.html",
    skybattle: "Sky_Battle_10/index.html",
    ballpol: `8ball9/index.html?roomID=${gameID}&playerID=${auth.currentUser.uid}&playername=${auth.currentUser.displayName}`,
    battleship: `battle_ship2/index.html?roomID=${gameID}&playerID=${auth.currentUser.uid}&playerName=${auth.currentUser.displayName}`,
    crazy_cannons: `crazycannons/index.html?roomID=${gameID}&playerID=${auth.currentUser.uid}&playerName=${auth.currentUser.displayName}`,
    box_tower: `boxTower1/index.html?roomID=${gameID}&playerID=${auth.currentUser.uid}&playerName=${auth.currentUser.displayName}&username=${auth.currentUser.displayName}`,
    "head-soccer": `https://uno.thegamehub.gg:3006/index.html?roomname=${gameID}&playerid=${auth.currentUser.uid}&playerName=${auth.currentUser.displayName}&numOfPlayers=${players_count}&isHost=${isCasa}`,
    chess: `https://uno.thegamehub.gg:3011/index.html?roomID=${gameID}&playerID=${auth.currentUser.uid}&playerName=${auth.currentUser.displayName}&numOfPlayers=${players_count}&isHost=${isCasa}`,
  };

  const onlyPorttrait = {
    skybattle: true,
    ballpol: true,
    crazy_cannons: true,
    box_tower: true,
  };

  const [youWin, setYouWin] = useState(false);
  const [gameFinished, setGameFinisged] = useState(false);

  const [opponentConnected, setOpponentConnected] = useState(true);
  const [emoji2player, setEmoji2Player] = useState(null);

  const connectionRef = ref(
    rdb,
    "active_games/new_pvp/" + gameID + "/connected"
  );
  //  const singleConnectedRef = ref(rdb, "active_games/new_pvp/"+ gameID + "/connected/" + auth.currentUser.uid);
  const OpponentEmojiRef = ref(
    rdb,
    "active_games/new_pvp/" + gameID + "/emoji"
  );

  const [friendInvited, setFriendInvited] = useState({});

  const [isLandscape, setIsLandscape] = useState(
    window.innerWidth > window.innerHeight
  );

  function back() {
    nav("/?username=" + tgUsernameGlob + "&id=" + telegramID);
  }
  const handleResize = () => {
    setH(window.innerHeight);
    setIsLandscape(window.innerWidth > window.innerHeight);
  };
  useEffect(() => {
    console.log("my uid", auth.currentUser.uid);
    if (isSpectator !== 1) {
      onChildChanged(OpponentEmojiRef, onEmojiChanged);
      checkDisconnection();
      onValue(connectionRef, onConnectedChange);
      checkIdsPresenceFriends(players);
    }
  }, []);

  function checkDisconnection() {
    onDisconnect(connectionRef).update({ [auth.currentUser.uid]: false });
  }
  const onConnectedChange = (snapshot) => {
    if (snapshot.val() == null) {
      onDisconnect(connectionRef).cancel();
      off(connectionRef, "value", onConnectedChange);
      return;
    }
    const connectedAll = snapshot.val();
    let numDisc = 0;
    Object.entries(connectedAll).forEach(([key, value]) => {
      if (value === false) numDisc++;
    });
    if (numDisc >= players_count - 1) {
      setOpponentConnected(false);
    }
  };

  async function checkIdsPresenceFriends(ids) {
    const presenceMap = {};
    const presencePromises = ids.map(async (uid) => {
      if (uid !== auth.currentUser.uid) {
        const friendRef = collection(
          db,
          "users",
          auth.currentUser.uid,
          "friends"
        );
        const q = query(friendRef, where("uid", "==", uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          presenceMap[uid] = querySnapshot.docs[0].data().username;
        }
      }
    });
    await Promise.all(presencePromises);
    setFriendInvited(presenceMap);
  }

  async function postFinished() {
    console.log("game finished");
    setGameFinisged(true);
    onDisconnect(connectionRef).cancel();
    off(connectionRef, "value", onConnectedChange);
    //  const r = ref(rdb, "active_games/new_pvp/"+ gameID + "/finished/" + auth.currentUser.uid);
    //  await set(r,true);
  }

  async function postWin(winner) {
    //setGameFinisged(true);
    //clearInterval(timerIntervalRef.current);
    if (winner !== auth.currentUser.uid) return;
    setYouWin(true);
    try {
      const funct = httpsCallable(functions, "onPvpGameWin");
      const res = await funct({ gameID: gameID });
      console.log(res);
    } catch (error) {
      console.log("error win", error);
      showErrorNotification("Error win");
    }
    // const r = ref(rdb, "active_games/new_pvp/"+ gameID + "/winner");
    //  await set(r,winner);
  }
  function onEmojiChanged(snapshot) {
    const uid = snapshot.key;
    const emojiDetails = snapshot.child("name").val();

    const iframe = document.getElementById("gameFrame");
    iframe.contentWindow.postMessage(
      { type: "emoji_" + url, playerID: uid, emojiName: emojiDetails },
      "*"
    );

    if (players_count === 2) {
      setEmoji2Player({ ...snapshot.val(), uid: uid });
      setTimeout(() => setEmoji2Player(null), 3000);
    }
  }
  async function postNewEmoji(name) {
    const myEmojiRef = ref(
      rdb,
      "active_games/new_pvp/" + gameID + "/emoji/" + auth.currentUser.uid
    );
    await set(myEmojiRef, { name: name, date: Date.now() });
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    const iframe = document.getElementById("gameFrame");
    iframe.contentWindow.levelToPlay = level;
    iframe.contentWindow.username = auth.currentUser.displayName;
    iframe.contentWindow.playerName = auth.currentUser.displayName;
    iframe.contentWindow.roomID = gameID;
    iframe.contentWindow.playerID = auth.currentUser.uid;
    iframe.contentWindow.numberOfPlayers = players_count;
    iframe.contentWindow.isHost = isCasa ? 1 : 0;
    iframe.contentWindow.gameMode = "Battle royale";
    iframe.contentWindow.spectator = isSpectator;

    if (url === "ballpol") {
      iframe.onmousedown = () => {
        iframe.style.pointerEvents = "none";
      };
      iframe.onmouseup = () => {
        iframe.style.pointerEvents = "auto";
      };
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const riceviMessaggio = (event) => {
      console.log(event.data);

      if (event.data.type === "finished_" + url) {
        postFinished();
      }
      if (event.data.type === "win_" + url) {
        console.log("winner: ", event.data.winner);
        postWin(auth.currentUser.uid);
      }
    };

    if (isSpectator !== 1) window.addEventListener("message", riceviMessaggio);

    return () => {
      window.removeEventListener("message", riceviMessaggio);
    };
  }, []);

  function OpponentDisconnectedWid() {
    return (
      <div
        style={{
          zIndex: 4,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: h,
          backgroundColor: ColorPalette.bgColor,
        }}
      >
        <RColumn>
          <Space />
          <RText align={"center"} weight="bold" size="24px">
            Opponent disconnected
          </RText>
          <Space />
          <Space />
          <PrimaryBtn onClick={back} text={"Close"} />
        </RColumn>
      </div>
    );
  }

  async function sendInviteReqest(uid) {
    setFriendInvited((prev) => ({ ...prev, [uid]: true }));
    const funct = httpsCallable(functions, "addFriend");
    try {
      const res = await funct({ uid: uid });
      //console.log(res);
      if (res.data.success !== true) {
        showErrorNotification(res.data.msg);
        return;
      }
      showSuccessNotification("Friend invited");
    } catch (error) {
      showErrorNotification("There was an error");
    }
  }
  function FinishPanel() {
    return (
      <div style={{ background: ColorPalette.bgGradient, height: "100vh" }}>
        <RColumn>
          <Space height="90px" />
          <img src={`gameIcons/${url}.png`} width={"90px"} />
          <RTitile>Game finished!</RTitile>
          <Space />
          {youWin && (
            <>
              <RTitile>You wonn!</RTitile>
              <Space />
              <RText>+ ${price * players_count * 0.95 - price}</RText>
              <Space />
            </>
          )}

          <Space height="120px" />
          {gameFinished && <PrimaryBtn onClick={back} text={"Close"} />}
        </RColumn>
      </div>
    );
  }
  return (
    <>
      {isSpectator === 1 && <BackToHomeBtn />}
      {isSpectator !== 1 && emoji2player && (
        <div
          style={{
            position: "fixed",
            top: emoji2player.uid !== auth.currentUser.uid ? "12px" : null,
            bottom: emoji2player.uid === auth.currentUser.uid ? "12px" : null,
            left: "12px",
          }}
        >
          <img src={"gameEmoji/" + emoji2player.name + ".gif"} width={"50px"} />
        </div>
      )}
      {onlyPorttrait[url] && !isLandscape && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            background: ColorPalette.bgGradient,
            zIndex: 4,
          }}
        >
          <RColumn
            height={"100%"}
            verticalAlignment="center"
            horizontalAlign="center"
          >
            <RTitile>Rotate the device</RTitile>
          </RColumn>
        </div>
      )}
      <iframe
        id="gameFrame"
        width="100%"
        height={h - 5}
        src={urlToIndexHtml[url]}
        style={{ border: "none" }}
      ></iframe>
      {isSpectator !== 1 && (
        <EmojiPanel onSelectedEmoji={(name) => postNewEmoji(name)} />
      )}
      {isSpectator !== 1 && gameFinished && opponentConnected && (
        <FinishPanel />
      )}
      {isSpectator !== 1 && !opponentConnected && <OpponentDisconnectedWid />}
      {/*  <div style={{position: "fixed", left: "10px", bottom: "10px", zIndex: 4}}>
            <dvi onClick={back}>Exit</dvi>
        </div> */}
    </>
  );
}
