import { RiInstagramFill } from "@remixicon/react";
import { ContainerBorder } from "../compnents/compes";
import {
  RColumn,
  RGrid,
  RRow,
  RText,
  RTitile,
  SecondaryBtn,
  Space,
  SpaceHorizontal,
  TextButton,
} from "../compnents/otherCOmps";
import { ColorPalette, isTest, TaskDoc } from "../global/const";
import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { auth, db, functions } from "../firebase";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import LoadingIcons from "react-loading-icons";
import { showErrorNotification } from "../compnents/errorMessagesComp";
import { showAds } from "../adsGram/adsManager";
import { isDesktop } from "react-device-detect";

export default function TaskPage({ changeTabIndex }) {
  const [verificationStatus, setVerificationStatus] = useState();

  const [taskStatus, setTaskStatus] = useState({});
  const [canWriteTask, setCanWriteTask] = useState({});
  const [taskDownload, setTaskDownloaded] = useState(false);

  const taskTypes = [
    { type: "all", label: "All", index: 0 },
    { type: "social_media_eng", label: "Social", index: 1 },
    { type: "referral", label: "Referral", index: 2 },
    { type: "pvp", label: "Games", index: 3 },
    { type: "partner", label: "Partners", index: 4 },
    { type: "deposit", label: "Wallet", index: 5 },
  ];
  const [taskTypeSelected, setTaskTypeSelected] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  const fromParam = queryParams.get("from");

  useEffect(() => {
    const taskRef = doc(db, "users-tasks/" + auth.currentUser.uid);

    const unsubscribe = onSnapshot(taskRef, (docSnapshot) => {
      setTaskDownloaded(true);
      if (docSnapshot.exists()) {
        setTaskStatus(docSnapshot.data()?.tasks);
      } else {
        setTaskStatus(null);
      }
    });

    const queryParams = new URLSearchParams(window.location.search);
    const error = queryParams.get("error");
    if (error) showErrorNotification(error);

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const taskRef = doc(
      db,
      "users/" + auth.currentUser.uid + "/can_write/tasks"
    );

    const unsubscribe = onSnapshot(taskRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setCanWriteTask(docSnapshot.data());
      }
    });

    const queryParams = new URLSearchParams(window.location.search);
    const error = queryParams.get("error");
    if (error) showErrorNotification(error);

    return () => unsubscribe();
  }, []);

  async function verifyTask(taskId, taskSubType) {
    setVerificationStatus((p) => ({ ...p, [taskId]: "loading" }));

    const funct = httpsCallable(functions, "verifyTask");

    try {
      const r = await funct({ task_id: taskId });
      console.log(r.data);
      if (!r.data.success) {
        console.log(r.data);
        setVerificationStatus((p) => ({ ...p, [taskId]: "error" }));
        return;
      }
      if (taskSubType === "twitter_login") {
        if (isDesktop) {
          window.open(r.data.url, "_blank");
        } else {
          window.location = r.data.url;
        }
      } else {
        setVerificationStatus((p) => ({ ...p, [taskId]: "done" }));
      }
    } catch (error) {
      console.log(error);
      setVerificationStatus((p) => ({ ...p, [taskId]: "error" }));
    }
  }
  async function setStartedTask(id) {
    const taskDocRef = doc(
      db,
      "users/" + auth.currentUser.uid + "/can_write/tasks"
    );
    await setDoc(
      taskDocRef,
      {
        [id]: {
          started: true,
        },
      },
      { merge: true }
    );
  }
  async function goToLinkStart(task) {
    //  setVerificationStatus((p) => ({ ...p, [task.id]: "started" }));
    if (task.type === "referral") {
      window.scrollTo(0, 0);
      changeTabIndex(1);
      return;
    }
    if (task.type === "pvp") {
      window.scrollTo(0, 0);
      changeTabIndex(0);
      return;
    }
    if (task.type == "deposit") {
      changeTabIndex(2);
      return;
    }
    if (task.id === "change_tg_name" || task.id === "change_tg_img") {
      await setStartedTask(task.id);
      window.open("https://t.me/" + auth.currentUser.displayName);
      return;
    }
    await setStartedTask(task.id);

    if (task.type === "ads") {
      showAds(
        isTest ? task.block_id_test.toString() : task.block_id.toString()
      );
      return;
    }

    if (!task.link) return;
    window.open(task.link, "_blank", "noopener,noreferrer");
  }
  function SingleTask({ task }) {
    const isMaxDay = task.max_day;
    const midnight = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        0,
        0,
        0,
        0
      )
    );
    const lastIsBeforeToday = (taskStatus?.[task.id]?.date ?? 0) < midnight;

    const maxReached = lastIsBeforeToday
      ? false
      : (taskStatus?.[task.id]?.num_today ?? 0) >= task.max_day;

    const cantFromThisTarget =
      task.only_miniapp === true && fromParam !== "miniapp";
    return (
      <ContainerBorder
        gradientBg={taskStatus?.[task.id]?.completed}
        padding="12px 10px"
      >
        <RRow horizontalAlign="space-between">
          <RRow>
            <img
              style={{ borderRadius: "100%" }}
              src={task.img}
              width={"22px"}
            />
            <SpaceHorizontal />
            <RColumn horizontalAlign="start">
              <RText size="15px">{task?.title}</RText>
              <RText color="grey" size="13px">
                +{task?.points} points{" "}
                {task.auto === true && !task.one_time ? "each" : ""}
                {isMaxDay &&
                  " (" +
                    (lastIsBeforeToday
                      ? "0"
                      : taskStatus[task.id]?.num_today ?? 0) +
                    "/" +
                    task.max_day +
                    " a day)"}
              </RText>
            </RColumn>
          </RRow>
          <SpaceHorizontal />
          {!taskStatus?.[task.id]?.completed ? (
            <RColumn horizontalAlign="center">
              {((isMaxDay && !maxReached && !cantFromThisTarget) ||
                (!isMaxDay && !cantFromThisTarget)) && (
                <SecondaryBtn
                  text={"Start"}
                  onClick={() => goToLinkStart(task)}
                />
              )}
              {task.auto !== true && (
                <>
                  <Space height="8px" />
                  {verificationStatus?.[task.id] === "loading" && (
                    <LoadingIcons.ThreeDots height={"6px"} width={"22px"} />
                  )}
                  {canWriteTask?.[task.id]?.started === true &&
                    verificationStatus?.[task.id] !== "loading" && (
                      <div
                        onClick={() => verifyTask(task.id, task.subType)}
                        style={{ cursor: "pointer" }}
                      >
                        {verificationStatus?.[task.id] === "error" ? (
                          <RColumn horizontalAlign="center">
                            <RText size="12px" color="red">
                              Error
                            </RText>
                            <RText size="12px">Try again</RText>
                          </RColumn>
                        ) : (
                          <RText size="12px">Verify</RText>
                        )}
                      </div>
                    )}
                </>
              )}
            </RColumn>
          ) : (
            <div style={{ width: "100px" }}>
              <RText align={"right"} color="green" size="12px">
                Completed!
              </RText>
            </div>
          )}
        </RRow>
        {cantFromThisTarget && !taskStatus?.[task.id]?.completed && (
          <div style={{ padding: "0px 12px" }}>
            <Space height="4px" />
            <RText color="red" size="14px">
              This task only works from telegram app, use /task from the tg bot
            </RText>
          </div>
        )}
      </ContainerBorder>
    );
  }

  const SingleTypeTask = ({ type }) => {
    return (
      <div
        onClick={() => setTaskTypeSelected(type)}
        style={{
          cursor: "pointer",
          backgroundColor:
            type == taskTypeSelected ? ColorPalette.accentColor2 : "",
          padding: "4px 8px",
          borderRadius: "8px",
          width: "90px",
        }}
      >
        <RText size="14px" align={"center"}>
          {taskTypes[type].label}
        </RText>
      </div>
    );
  };
  return (
    <>
      <Space />
      <RTitile>Tasks</RTitile>
      <Space />
      <div
        style={{
          backgroundColor: ColorPalette.accentColor2Bg,
          padding: "6px 6px",
          borderRadius: "12px",
        }}
      >
        <RGrid width={"100%"} horizontalAlign="space-between">
          {taskTypes.map((t, i) => (
            <SingleTypeTask type={t.index} key={i} />
          ))}
        </RGrid>
      </div>
      <Space />
      {taskDownload ? (
        <>
          {TaskDoc.tasks
            .filter((t) =>
              taskTypeSelected !== 0
                ? t.type === taskTypes[taskTypeSelected].type
                : true
            )
            .map((t, i) => (
              <SingleTask key={t.id} task={t} />
            ))}
        </>
      ) : (
        <LoadingIcons.ThreeDots width={"22px"} />
      )}
    </>
  );
}
