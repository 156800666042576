import { useEffect, useState } from "react";
import {
  InputField,
  LoadingScreen,
  PrimaryBtn,
  RColumn,
  RRow,
  RText,
  RTitile,
  Space,
  SpaceHorizontal,
} from "../compnents/otherCOmps";
import { ContainerBorder } from "../compnents/compes";
import { copyString, formatNumber } from "../global/util";
import { BaseInfo, StatusInfo } from "../global/const";
import { httpsCallable } from "firebase/functions";
import { auth, db, functions } from "../firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import LoadingIcons from "react-loading-icons";
import { useGameType } from "../theme";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../compnents/errorMessagesComp";

export default function WalletWidraw({ Head }) {
  const [tokenSelected, setTokenSelected] = useState(null);
  const [coinSelected, setCoinSelected] = useState({
    coin: null,
    chain: null,
    min_wid: null,
  });
  const [state, setState] = useState(0);
  const { userBalances } = useGameType();

  const [selectedAmountToWidth, setSelectedAmountToWid] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [isBalanceOk, setisBalanceOk] = useState(false);

  useEffect(() => {
    if (!StatusInfo.data.withdrawal) {
      setState("closed");
    }
  }, []);
  function handleAmountChange(e) {
    if (!e && e !== 0) {
      setSelectedAmountToWid("");
      return;
    }
    const num = parseFloat(e);
    const userBalance =
      userBalances.balances?.[tokenSelected.symbol.toLowerCase()]?.amount ?? 0;
    setisBalanceOk(true);

    if (userBalance < num || num < coinSelected.min_wid) {
      setisBalanceOk(false);
    }
    setSelectedAmountToWid(num);
    console.log(num);
  }

  async function onWidth() {
    console.log(selectedAmountToWidth, coinSelected.coin, walletAddress);
    setState("loading");
    try {
      const funct = httpsCallable(functions, "widthdrawNowPayments");
      const data = {
        amount: selectedAmountToWidth,
        coin: coinSelected.coin,
        wallet_address: walletAddress,
      };
      const res = await funct(data);
      if (!res.data.success) {
        throw { msg: res.data.msg };
      }

      setState(0);
      showSuccessNotification(
        "withdrawal sent, will process in upcoming time",
        4000
      );
    } catch (error) {
      setState(2);
      showErrorNotification(error.msg ?? "Error processing withdrawal");
    }
  }
  return (
    <div style={{ padding: "14px" }}>
      <Head>Withdrawal</Head>
      <Space />

      <Space height="8px" />
      {state === "closed" && (
        <>
          <Space />
          <RTitile>Withdrawals are currently paused</RTitile>
          <Space />
          <RText>{StatusInfo.data.withdrawal_msg}</RText>
          <Space height="12px" />
          <RText>Thank you for your patience</RText>
        </>
      )}
      {state === "loading" && (
        <>
          <LoadingScreen />
        </>
      )}
      {state === 0 && (
        <>
          <RText size="18px">Choose the token</RText>
          {BaseInfo.baseInfoDoc.coinSupported.map((v, i) => (
            <ContainerBorder
              key={v.symbol}
              padding="10px"
              onClick={() => {
                setTokenSelected(v);
                setState(1);
              }}
            >
              <RRow>
                <img src={v.img} width={"22px"} />
                <SpaceHorizontal />
                <RColumn horizontalAlign="start">
                  <RText size="18px">{v.symbol}</RText>
                  <Space height="4px" />
                  <RText size="16px" color={"grey"}>
                    {v.name}
                  </RText>
                </RColumn>
              </RRow>
            </ContainerBorder>
          ))}
        </>
      )}
      {state === 1 && (
        <>
          <RRow horizontalAlign="start">
            <img src={tokenSelected.img} width={"22px"} />
            <SpaceHorizontal />
            <RColumn horizontalAlign="start">
              <RText size="18px">{tokenSelected.symbol}</RText>
              <Space height="4px" />
              <RText size="16px" color={"grey"}>
                {tokenSelected.name}
              </RText>
            </RColumn>
          </RRow>
          <Space />
          <RText>Choose Blockchain</RText>
          <Space height="8px" />
          {tokenSelected.chains.map((v, i) => (
            <ContainerBorder
              key={v.name}
              padding="14px 12px"
              onClick={() => {
                setCoinSelected({
                  coin: v.coin,
                  chain: v.name,
                  min_wid: v.min_wid,
                });
                setState(2);
              }}
            >
              <RText>{v.name}</RText>
            </ContainerBorder>
          ))}
        </>
      )}
      {state === 2 && (
        <>
          <RColumn>
            <img src={tokenSelected.img} width={"42px"} />
            <Space height="12px" />
            <RText>Withdraw {tokenSelected.symbol}</RText>
          </RColumn>

          <Space />
          <RText size="16px" color={"grey"}>
            Your balance
          </RText>
          <Space height="4px" />
          <RText>
            {userBalances.balances?.[tokenSelected.symbol.toLowerCase()]
              ?.amount ?? 0}{" "}
            {tokenSelected.symbol}
          </RText>
          <Space />
          <RText size="16px" color={"grey"}>
            Network
          </RText>
          <Space height="4px" />
          <RText>{coinSelected.chain}</RText>
          <Space />
          <RText size="16px" color={"grey"}>
            Minimum Withdrawal amount
          </RText>
          <Space height="4px" />
          <RText>
            {coinSelected.min_wid ?? "-"} {tokenSelected.symbol}
          </RText>
          <Space />
          <RText size="16px" color={"grey"}>
            Withdrawal Amount
          </RText>
          <Space height="4px" />
          <InputField
            placeHolder={"Amount"}
            type={"number"}
            value={selectedAmountToWidth}
            setValue={(e) => handleAmountChange(e.target.value)}
          />
          {!isBalanceOk && (
            <RText size="14px" color="red">
              Balance invalid
            </RText>
          )}
          <Space />
          <RText size="16px" color={"grey"}>
            Withdrawal Address
          </RText>
          <Space height="4px" />
          <InputField
            placeHolder={"Your address"}
            value={walletAddress}
            setValue={(e) => setWalletAddress(e.target.value)}
          />
          <Space height="32px" />
          <RColumn>
            {isBalanceOk && <PrimaryBtn text={"Widthdraw"} onClick={onWidth} />}
          </RColumn>
        </>
      )}
      <Space />
    </div>
  );
}
