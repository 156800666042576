export const getPriceToken = async (token) => {
  if (token.toLowerCase() === "usdt" || token.toLowerCase() === "usdc")
    return 1;
  try {
    const response = await fetch(
      `https://tonapi.io/v2/rates?tokens=${token}&currencies=usd`
    );
    if (!response.ok) {
      throw new Error("Errore nella richiesta");
    }

    const data = await response.json();
    /*  const currencyRate = Object.values(data.rates)[0];

    const usdPrice = Object.values(currencyRate.prices)[0];
    return usdPrice; */

    return data.rates?.[token.toUpperCase()]?.prices?.["USD"] ?? 0;
  } catch (e) {
    throw e;
  }
};
